import React, { useState } from 'react';
import styled from 'styled-components';
import Filters from '../Filters';
import { TextHeader } from '../Text';
import { Button } from '../Button';
import Auth from '@aws-amplify/auth';
import ScaleMetric from '../ScaleMetric';

const Admin = () => {
  const [showHairstylesTab, setShowHairstylesTab] = useState(true);
  const [showScaleMetricTab, setShowScaleMetricTab] = useState(false);

  const signOut = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    Auth.signOut().then(() => window.location.reload());
  };
  return (
    <Container>
      <HeaderWrapper>
        <Navigation>
          <Button
            active={showHairstylesTab}
            disabled={false}
            onClick={() => {
              setShowHairstylesTab(true);
              setShowScaleMetricTab(false);
            }}
          >
            Hairstyles
          </Button>
          <Button
            active={showScaleMetricTab}
            disabled={false}
            onClick={() => {
              setShowHairstylesTab(false);
              setShowScaleMetricTab(true);
            }}
          >
            Scale Metric
          </Button>
        </Navigation>
        <TextHeader>Peruka Admin</TextHeader>
        <SignOut>
          <Button
            disabled={false}
            active={true}
            style={{ width: '150px', height: '40px' }}
            onClick={signOut}
          >
            sign out
          </Button>
        </SignOut>
      </HeaderWrapper>
      <LineBreaker />
      {showHairstylesTab ? <Filters /> : <></>}
      {showScaleMetricTab ? <ScaleMetric /> : <></>}
    </Container>
  );
};

const Container = styled.div`
  margin: auto;
  max-width: 1440px;
`;

const Navigation = styled.div`
  flex: 1;
  display: flex;
  gap: 15px;
`;

const HeaderWrapper = styled.div`
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SignOut = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const LineBreaker = styled.div`
  position: absolute;
  left: 0;
  width: 100%;
  border-bottom: 1px solid black;
`;

export default Admin;
