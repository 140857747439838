import React from 'react';
import styled from 'styled-components';
import Admin from './components/Admin';
import { COGNITO } from './configs/aws';
import { Amplify } from 'aws-amplify';
import { withAuthenticator } from '@aws-amplify/ui-react';

Amplify.configure({
  aws_cognito_region: COGNITO.REGION,
  aws_user_pools_id: COGNITO.USER_POOL_ID,
  aws_user_pools_web_client_id: COGNITO.APP_CLIENT_ID,
});

function App() {
  return (
    <Container>
      <Admin />
    </Container>
  );
}

const Container = styled.div`
  margin: auto;
  max-width: 1440px;
`;

export default withAuthenticator(App);
