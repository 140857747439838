import React, { useState } from 'react';
import styled from 'styled-components';
import DatePicker from 'react-datepicker';
import Moment from 'moment';

import 'react-datepicker/dist/react-datepicker.css';
import { TextLabel } from '../Text';
import { Button } from '../Button';
import PerukaAdminApi from '../../api/PerukaAdminApi';

const load = require('../../assets/load.gif');
const DATE_FORMAT: string = 'yyyy-MM-DD';

const ScaleMetric = () => {
  const [loading, setLoading] = useState(false);
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [resultUrl, setResultUrl] = useState<string | null>(null);

  const updateFromDate = (date: Date) => {
    setFromDate(date);
    if (toDate < date) {
      setToDate(date);
    }
  };

  const generateReport = async () => {
    if (fromDate !== null && toDate !== null) {
      setLoading(true);
      const response = await PerukaAdminApi.generateScaleMetricReport(
        Moment(fromDate).format(DATE_FORMAT),
        Moment(toDate).format(DATE_FORMAT),
      );
      if (response.data && response.data.presignedUrl) {
        setResultUrl(response.data.presignedUrl);
        setLoading(false);
      }
    }
  };

  return (
    <Container>
      {loading ? (
        <StatusContainer>
          <img src={load} alt="img" />
        </StatusContainer>
      ) : (
        <Wrapper>
          <ParamWrapper>
            <TextLabel>Date from</TextLabel>
            <MetricDatePicker
              correct={fromDate !== null}
              selected={fromDate}
              onChange={(date: Date) => updateFromDate(date)}
              selectsStart
              startDate={fromDate}
              endDate={toDate}
              placeholderText="select from date"
            />
          </ParamWrapper>
          <ParamWrapper>
            <TextLabel>Date to</TextLabel>
            <MetricDatePicker
              correct={toDate !== null}
              selected={toDate}
              onChange={(date: Date) => setToDate(date)}
              selectsEnd
              startDate={fromDate}
              endDate={toDate}
              minDate={fromDate}
              placeholderText="select to date"
            />
          </ParamWrapper>
          <Button
            disabled={fromDate === null || toDate === null}
            active={true}
            onClick={generateReport}
          >
            Generate csv
          </Button>
          {resultUrl !== null ? (
            <Button
              style={{ marginLeft: '100px' }}
              disabled={false}
              active={true}
            >
              <ResultLink href={resultUrl}>Download csv</ResultLink>
            </Button>
          ) : (
            <></>
          )}
        </Wrapper>
      )}
    </Container>
  );
};

const Container = styled.div`
  padding: 1%;

  border-bottom: 1px solid black;
  border-left: 1px solid black;
  border-right: 1px solid black;
  border-radius: 10px;
`;

const StatusContainer = styled.div`
  text-align: center;
`;

const Wrapper = styled.div`
  align-items: center;
  display: flex;
`;

const ParamWrapper = styled.div`
  margin-right: 100px;
  min-width: 50px;
`;

const MetricDatePicker = styled(DatePicker)<{ correct: boolean }>`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  border: 1px solid ${(props) => (props.correct ? 'black' : 'red')};
  border-radius: 5px;
`;

const ResultLink = styled.a`
  color: white;
  text-decoration: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
`;

export default ScaleMetric;
