import styled from 'styled-components';

export const Button = styled.div<{ active: boolean; disabled: boolean }>`
  width: 150px;
  height: 40px;
  text-align: center;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  display: flex;
  gap: 1%;
  border: 1px solid #000000;
  border-radius: 5px;

  background: ${(props) =>
    props.active ? (props.disabled ? 'gray' : '#000000') : '#ffffff'};

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 27px;
  text-transform: lowercase;

  -webkit-touch-callout: none;
  -webkit-user-select: none;

  color: ${(props) => (props.active ? '#ffffff' : '#000000')};

  pointer-events: ${(props) => (props.disabled ? 'none' : 'all')};
`;
