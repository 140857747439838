import { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button } from '../Button';
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd';
import { TextLabel } from '../Text';
import PerukaAdminApi from '../../api/PerukaAdminApi';
import { FilterResponseDto } from '../../api/types/FilterResponseDto';
import { useDropzone } from 'react-dropzone';
import { FilterRequestDto } from '../../api/types/FilterRequestDto';
import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';

const load = require('../../assets/load.gif');
const logo = require('../../assets/peruka-logo.gif');

interface Filter {
  id: number;
  url: string;
  type: string;
  index: number;
  deletable: boolean;
  new: boolean;
  image: File | null;
  activeFrom: Date | null;
  activeTo: Date | null;
}

enum Type {
  FREE = 'FREE',
  PAID = 'PAID',
}

const emptyFilter: Filter = {
  id: -1,
  url: '',
  type: Type.FREE,
  index: -1,
  deletable: false,
  new: true,
  image: null,
  activeFrom: null,
  activeTo: null,
};

const Filters = () => {
  const [loading, setLoading] = useState(true);
  const [sourceFilters, setSourceFilters] = useState<FilterResponseDto[]>([]);
  const [filters, setFilters] = useState<Filter[]>([]);
  const [filtersIds, setFiltersIds] = useState<number[]>([]);
  const [filtersIdsToDelete, setFiltersIdsToDelete] = useState<number[]>([]);
  const [editFilter, setEditFilter] = useState<Filter>(emptyFilter);
  const [showEditView, setShowEditView] = useState(false);
  const [filtersChanged, setFiltersChanged] = useState(false);

  const onFileSelect = useCallback(
    (acceptedFile) => {
      let tempEditFilter: Filter = getEditFilter();
      tempEditFilter.url = URL.createObjectURL(acceptedFile[0]);
      tempEditFilter.image = acceptedFile[0];
      setEditFilter(tempEditFilter);
    },
    // eslint-disable-next-line
    [editFilter],
  );

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/png': [],
    },
    multiple: false,
    onDrop: onFileSelect,
  });

  const loadFilters = () => {
    (async () => {
      setLoading(true);
      const filtersResponse: FilterResponseDto[] = await getFilters();
      const tempFilters: Filter[] = [];
      const tempFiltersIds: number[] = [];
      filtersResponse.forEach((filter) => {
        tempFilters.push({
          id: filter.id,
          url: filter.imageUrl,
          type: filter.type,
          index: filter.index,
          deletable: false,
          new: false,
          image: null,
          activeFrom:
            filter.activeFrom === '' ? null : new Date(filter.activeFrom),
          activeTo: filter.activeTo === '' ? null : new Date(filter.activeTo),
        });
        tempFiltersIds.push(filter.id);
      });
      setFilters(tempFilters);
      setSourceFilters(filtersResponse);
      setFiltersIds(tempFiltersIds);
      setFiltersIdsToDelete([]);
      setLoading(false);
      setFiltersChanged(false);
    })();
  };

  const getFilters = async () => {
    const response = await PerukaAdminApi.getFilters();
    if (response.data && response.data.body) {
      const body: FilterResponseDto[] = response.data.body;
      return body.sort((a, b) => a.index - b.index);
    }
    return [];
  };

  useEffect(() => {
    loadFilters();
    // eslint-disable-next-line
  }, []);

  const onDragEnd = (result: DropResult) => {
    const { source, destination } = result;
    if (!destination) return;

    const tempFilters = Array.from(filters);
    tempFilters[source.index].index = destination.index;
    const [newOrder] = tempFilters.splice(source.index, 1);
    tempFilters.splice(destination.index, 0, newOrder);

    tempFilters.forEach((item, index) => {
      item.index = index;
    });

    setFilters(tempFilters);
  };

  const applyChanges = async () => {
    setLoading(true);
    if (await isPossibleToUpdate()) {
      const filtersWithNewImages = await uploadNewImages();
      const requestFilters = Array.from(filters);
      filtersWithNewImages.forEach((filter) => requestFilters.splice(filter.index, 1, filter));
      const filtersToAdd: FilterRequestDto[] = [];
      const filtersToUpdate: FilterRequestDto[] = [];
      requestFilters.forEach((filter) => {
        const tempFilter: FilterRequestDto = {
          id: filter.id,
          url: filter.url,
          index: filter.index,
          type: filter.type,
          imageUpdated: filter.image !== null,
          activeFrom:
            filter.activeFrom === null
              ? ''
              : filter.activeFrom.toLocaleDateString('en-US'),
          activeTo:
            filter.activeTo === null
              ? ''
              : filter.activeTo.toLocaleDateString('en-US'),
        };
        if (filter.new) {
          filtersToAdd.push(tempFilter);
        } else {
          filtersToUpdate.push(tempFilter);
        }
      });
      await PerukaAdminApi.manageFilters({
        filtersToDelete: filtersIdsToDelete,
        filtersToAdd: filtersToAdd,
        filtersToUpdate: filtersToUpdate,
      });
      loadFilters();
    } else {
      setLoading(false);
      setFiltersChanged(true);
    }
  };

  const isPossibleToUpdate = async () => {
    const filtersResponse: FilterResponseDto[] = await getFilters();
    return sourceFilters.every((filter, index) => {
      const responseFilter = filtersResponse[index];
      return (
        filter.index === responseFilter.index &&
        filter.id === responseFilter.id &&
        filter.imageUrl === responseFilter.imageUrl &&
        filter.type === responseFilter.type &&
        filter.activeFrom === responseFilter.activeFrom &&
        filter.activeTo === responseFilter.activeTo
      );
    });
  };

  const uploadNewImages = async (): Promise<Filter[]> => {
    const updatedFilters = filters
      .filter((filter) => filter.image !== null)
      .map(async (filter) => await uploadImage(filter));

    return await Promise.all(updatedFilters);
  };

  const uploadImage = async (filter: Filter): Promise<Filter> => {
    const filterId = filter.id.toString();
    const response = await PerukaAdminApi.getUploadUrl(filterId);
    const url = response.data.presignedUrl;
    await fetch(url, {
      method: 'PUT',
      body: filter.image,
    });
    filter.url = response.data.imageLink;
    return filter;
  };

  const saveFilter = () => {
    if (editFilter.index === -1) {
      if (!checkEditImage() && !checkEditId()) {
        let tempEditFilter: Filter = getEditFilter();
        tempEditFilter.index = filters.length;
        const tempFilters = Array.from(filters);
        tempFilters.push(tempEditFilter);
        setFilters(tempFilters);
        setShowEditView(false);
      }
    } else {
      if (!checkEditImage()) {
        const tempFilters = Array.from(filters);
        tempFilters.splice(editFilter.index, 1, editFilter);
        setFilters(tempFilters);
        setShowEditView(false);
      }
    }
  };

  const updateFilterId = (value: string) => {
    value.replace(/\D/g, '');
    let tempEditFilter: Filter = getEditFilter();
    tempEditFilter.id = Number(value);
    setEditFilter(tempEditFilter);
  };

  const updateType = (value: string) => {
    let tempEditFilter: Filter = getEditFilter();
    tempEditFilter.type = value;
    setEditFilter(tempEditFilter);
  };

  const updateActiveFromDate = (value: Date) => {
    let tempEditFilter: Filter = getEditFilter();
    tempEditFilter.activeFrom = value;
    setEditFilter(tempEditFilter);
  };

  const updateActiveToDate = (value: Date) => {
    let tempEditFilter: Filter = getEditFilter();
    tempEditFilter.activeTo = value;
    setEditFilter(tempEditFilter);
  };

  const deleteFilter = (index: number) => {
    const tempFilters = Array.from(filters);
    const tempFilter = tempFilters[index];
    if (tempFilter.deletable) {
      tempFilters.splice(index, 1);
      tempFilters.forEach((item, index) => {
        item.index = index;
      });
      let filtersToDelete = Array.from(filtersIdsToDelete);
      filtersToDelete.push(tempFilter.id);
      setFiltersIdsToDelete(filtersToDelete);

      setFiltersIds(
        tempFilters.map((filter) => {
          return filter.id;
        }),
      );
    } else {
      tempFilter.deletable = true;
    }
    setFilters(tempFilters);
  };

  const sortFilters = () => {
    const freeFilters: Filter[] = [];
    const paidFilters: Filter[] = [];
    filters.forEach((filter) => {
      if (filter.type === Type.FREE) {
        freeFilters.push(filter);
      } else {
        paidFilters.push(filter);
      }
    });
    const newOrder = freeFilters.concat(paidFilters);
    newOrder.forEach((filter, index) => {
      filter.index = index;
    });

    setFilters(newOrder);
  };

  const checkEditId = () => {
    return editFilter.id < 1 || filtersIds.includes(editFilter.id);
  };

  const checkEditImage = () => {
    return editFilter.url === '';
  };

  const getEditFilter = () => {
    return {
      id: editFilter.id,
      url: editFilter.url,
      type: editFilter.type,
      index: editFilter.index,
      deletable: editFilter.deletable,
      new: editFilter.new,
      image: editFilter.image,
      activeFrom: editFilter.activeFrom,
      activeTo: editFilter.activeTo,
    };
  };
  return (
    <Container>
      {loading ? (
        <StatusContainer>
          <img src={load} alt="img" />
        </StatusContainer>
      ) : showEditView ? (
        <ParametersWrapper>
          <Controls>
            <Button
              disabled={false}
              active={true}
              style={{ width: '40px' }}
              onClick={() => {
                setShowEditView(false);
              }}
            >
              &#x2718;
            </Button>
            <Button
              disabled={false}
              active={true}
              style={{ width: '40px' }}
              onClick={() => {
                saveFilter();
              }}
            >
              &#x2714;
            </Button>
          </Controls>
          <ParametersContainer>
            <ParamWrapper>
              <ImgWrapper
                style={{ border: checkEditImage() ? '1px solid red' : '' }}
              >
                <Img src={checkEditImage() ? logo : editFilter.url} alt="img" />
              </ImgWrapper>
              <UploadImage>
                <input {...getInputProps()} />
                <Button
                  {...getRootProps()}
                  disabled={false}
                  active={true}
                  style={{ height: '30px', width: '150px' }}
                >
                  Browse file
                </Button>
              </UploadImage>
            </ParamWrapper>
            <ParamWrapper>
              <TextLabel>Id</TextLabel>
              {editFilter.index === -1 ? (
                <Input
                  error={checkEditId()}
                  value={editFilter.id}
                  onChange={(event) => {
                    updateFilterId(event.target.value);
                  }}
                />
              ) : (
                <TextLabel>{editFilter.id}</TextLabel>
              )}
            </ParamWrapper>
            <ParamWrapper>
              <TextLabel>Type</TextLabel>
              <ButtonsWrapper>
                <Button
                  disabled={false}
                  style={{ height: '30px', width: '120px' }}
                  active={editFilter.type === Type.FREE}
                  onClick={() => {
                    updateType(Type.FREE);
                  }}
                >
                  {Type.FREE}
                </Button>
                <Button
                  disabled={false}
                  style={{ height: '30px', width: '120px' }}
                  active={editFilter.type === Type.PAID}
                  onClick={() => {
                    updateType(Type.PAID);
                  }}
                >
                  {Type.PAID}
                </Button>
              </ButtonsWrapper>
            </ParamWrapper>
            <ParamWrapper>
              <TextLabel>Active from</TextLabel>
              <ActiveDatePicker
                selected={editFilter.activeFrom}
                onChange={(date: Date) => updateActiveFromDate(date)}
              />
            </ParamWrapper>
            <ParamWrapper>
              <TextLabel>Active to</TextLabel>
              <ActiveDatePicker
                selected={editFilter.activeTo}
                onChange={(date: Date) => updateActiveToDate(date)}
              />
            </ParamWrapper>
          </ParametersContainer>
        </ParametersWrapper>
      ) : (
        <FiltersWrapper>
          {filtersChanged ? (
            <StatusContainer>
              <TextLabel style={{ color: 'red' }}>
                filters already changed, please refresh the page
              </TextLabel>
            </StatusContainer>
          ) : (
            <></>
          )}
          <ControlsContainer>
            <Sort>
              <PointerButton onClick={sortFilters}>
                <TextLabel>sort free at start</TextLabel>
              </PointerButton>
            </Sort>
            <Controls>
              <Button
                disabled={false}
                active={true}
                style={{ width: '40px' }}
                onClick={loadFilters}
              >
                &#8635;
              </Button>
              <Button
                disabled={false}
                active={true}
                onClick={() => {
                  setShowEditView(true);
                  setEditFilter(emptyFilter);
                }}
              >
                Add filter
              </Button>
              <Button disabled={false} active={true} onClick={applyChanges}>
                Apply changes
              </Button>
            </Controls>
          </ControlsContainer>
          <FiltersContainer>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable" direction="horizontal">
                {(provided) => (
                  <FiltersZone ref={provided.innerRef}>
                    {filters.map((filter, index) => (
                      <Draggable
                        key={filter.id}
                        draggableId={String(filter.id)}
                        index={index}
                      >
                        {(provided) => (
                          <FilterContainer
                            draggableStyle={provided.draggableProps.style}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <Edit>
                              <Button
                                disabled={false}
                                active={false}
                                onClick={() => {
                                  setEditFilter(filter);
                                  setShowEditView(true);
                                }}
                                style={{ width: '30px', height: '30px' }}
                              >
                                &#9998;
                              </Button>
                              <Button
                                disabled={false}
                                active={false}
                                onClick={() => deleteFilter(filter.index)}
                                style={{
                                  width: '30px',
                                  height: '30px',
                                  borderColor: filter.deletable
                                    ? 'red'
                                    : 'black',
                                }}
                              >
                                &#128465;
                              </Button>
                            </Edit>
                            <ImgWrapper>
                              <Img src={filter.url} alt="img" />
                            </ImgWrapper>
                            <TextLabel>Id: {filter.id}</TextLabel>
                            <TextLabel>Type: {filter.type}</TextLabel>
                            {(filter.activeFrom || filter.activeTo) && (
                              <ActiveContainer>
                                <TextLabel>&nbsp;</TextLabel>
                                <TextLabel>Active</TextLabel>
                                {filter.activeFrom && (
                                  <TextLabel>
                                    from:&nbsp;&nbsp;
                                    {filter.activeFrom.toLocaleDateString(
                                      'en-US',
                                    )}
                                  </TextLabel>
                                )}
                                {filter.activeTo && (
                                  <TextLabel>
                                    to:&nbsp;&nbsp;
                                    {filter.activeTo.toLocaleDateString(
                                      'en-US',
                                    )}
                                  </TextLabel>
                                )}
                              </ActiveContainer>
                            )}
                          </FilterContainer>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </FiltersZone>
                )}
              </Droppable>
            </DragDropContext>
          </FiltersContainer>
        </FiltersWrapper>
      )}
    </Container>
  );
};

const Container = styled.div`
  padding: 1% 1% 0 1%;

  border-bottom: 1px solid black;
  border-left: 1px solid black;
  border-right: 1px solid black;
  border-radius: 10px;
`;

const ParametersWrapper = styled.div`
  padding-bottom: 1%;
`;
const UploadImage = styled.div`
  padding-top: 5px;
`;

const ParametersContainer = styled.div`
  width: 98%;
  padding: 1%;
  border: 1px solid black;
  border-radius: 10px;
  align-items: center;
  display: flex;
`;

const ParamWrapper = styled.div`
  margin-right: 100px;
  min-width: 50px;
`;

const ActiveDatePicker = styled(DatePicker)`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  border: none;
  width: 100px;

  border-bottom: 1px solid black;
`;

const Input = styled.input<{ error: boolean }>`
  font-weight: 400;
  width: 100px;
  font-size: 16px;
  line-height: 24px;
  border: none;

  border-bottom: 1px solid ${(props) => (props.error ? 'red' : 'black')};
  margin-right: 10px;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  gap: 2%;
`;

const StatusContainer = styled.div`
  text-align: center;
`;

const FiltersWrapper = styled.div``;

const Controls = styled.div`
  padding-bottom: 1%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 15px;
`;

const Sort = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const PointerButton = styled.div`
  margin-right: 20px;
  cursor: pointer;
  text-decoration-line: underline;
  text-decoration-style: dotted;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
`;

const ControlsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FiltersContainer = styled.div`
  border: 1px solid black;
  border-radius: 10px;
  padding: 1% 1% 5px 1%;
  justify-content: center;
  margin-bottom: 1%;
`;

const FiltersZone = styled.div`
  display: flex;
  overflow: scroll;
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 0;
    height: 10px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
`;

const FilterContainer = styled.div<{ draggableStyle: any }>`
  padding: 10px;
  background: white;
  border: 1px solid black;
  font-size: 20px;
  border-radius: 5px;
  margin-bottom: 5px;
  margin-right: 5px;
  
  ...  ${(props) => props.draggableStyle}
`;

const ActiveContainer = styled.div``;

const ImgWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 150px;
  height: 150px;
  align-items: center;
`;

const Edit = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 5px;
`;

const Img = styled.img`
  height: 100%;
`;

export default Filters;
