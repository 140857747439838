import { AxiosResponse } from 'axios';
import createApiClient from './ApiClient';
import { GetPresignedUrlResponseDto } from './types/PresignedUrlResponseDto';
import { ManageFiltersRequestDto } from './types/ManageFiltersRequestDto';
import { GenerateReportRequestDto } from './types/GenerateReportRequestDto';

const routes = {
  getUploadUrl: () => `/url/perukaAdminGetUploadUrl`,
  getFilters: () => '/gf/perukaAdminGetFilters',
  manageFilters: () => '/mf/perukaAdminManageFilters',
  generateScaleMetricReport: () => '/gsmr/perukaAdminGenerateScaleMetricReport',
};

export default class PerukaAdminApi {
  private static client = createApiClient();

  public static getUploadUrl(
    filterId: string,
  ): Promise<AxiosResponse<GetPresignedUrlResponseDto>> {
    return this.client.post(routes.getUploadUrl(), {
      filterId: filterId,
    });
  }

  public static getFilters() {
    return this.client.get(routes.getFilters());
  }

  public static manageFilters(requestDto: ManageFiltersRequestDto) {
    return this.client.post(routes.manageFilters(), requestDto);
  }

  public static generateScaleMetricReport(fromDate: string, toDate: string) {
    const request: GenerateReportRequestDto = {
      fromDate: fromDate,
      toDate: toDate,
    };
    return this.client.post(routes.generateScaleMetricReport(), request);
  }
}
